import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { ToastContainer } from 'react-toastify';
import React, { useEffect } from 'react';
import { useAuth0 } from "./react-auth0-spa";
import FileViewerPage from "./components/FileViewerPage";
import { HashRouter, Route, Switch, useHistory } from 'react-router-dom';
import './scss/style.scss';

const showLoading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

const App = () => {
  const { isAuthenticated, loginWithRedirect, user, loading } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    const handleAuthentication = async () => {
      if (loading) return; // Wait until loading completes

      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: window.location.pathname + window.location.hash },
        });
      } else {
        const appState = JSON.parse(localStorage.getItem("auth0_appState"));
        if (appState?.targetUrl) {
          history.push(appState.targetUrl);
          localStorage.removeItem("auth0_appState");
        }
      }
    };

    handleAuthentication().catch((error) => {
      console.error("Error during authentication:", error);
    });
  }, [isAuthenticated, loginWithRedirect, loading, history]);

  if (loading || !user) {
    return showLoading();
  }

  return (
    <HashRouter>
      <React.Suspense fallback={showLoading()}>
        <Switch>
          <Route path="/download/:type/:fileName" component={FileViewerPage} />
          <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
        </Switch>
      </React.Suspense>
      <ToastContainer />
    </HashRouter>
  );
}
export default App;
