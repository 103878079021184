export default class ResponseModel {

    succeeded;
    object;
    errorMessage;

    constructor(succeded, element, errorMessage, additionalMessage,warnings) 
    {
        this.succeeded = succeded;
        this.object = element;
        this.errorMessage = errorMessage;
        this.additionalMessage = additionalMessage;
        this.warnings = warnings;
    }

    static responseModelOk(element, additionalMessage,warnings){
        return new ResponseModel(true, element, null, additionalMessage,warnings);
    }

    static responseModelKo(message){
        return new ResponseModel(false, null, message, null);
    }
}