import APICall from "../../utils/APICalls.js";
import b64toBlob from "b64-to-blob";

const FileServiceClient = {
    get: async function(type, fileName, auth0Client) {
        return await APICall.get(`File/${type}/${fileName}`, auth0Client);
    },

    upload: async function(fileContent, type, fileName, auth0Client) {
        return await APICall.post(`File/Download/${type}/${fileName}`, fileContent, auth0Client);
    },

    openFile: async function(pdfString, fileName, openInSameWindow, container = null) {
        var fileExtension = fileName.toLowerCase().split('.').pop();
        let applicationType = "application/octet-stream";
        switch (fileExtension) {
            case 'pdf':
                applicationType = "application/pdf";
                break;
            case 'xlsx':
                applicationType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                break;                
            default:
        }

        let blob = b64toBlob(pdfString, applicationType)
        const file = new Blob([blob], { type: applicationType });
        const fileURL = URL.createObjectURL(file);

        console.log("container: ", container);
        if (applicationType === "application/pdf" && container instanceof HTMLElement) {
            // Safely clear and append to the container
            while (container.firstChild) {
                container.removeChild(container.firstChild); // Remove existing child nodes
            }

            const embed = document.createElement('embed');
            embed.src = fileURL;
            embed.type = applicationType;
            embed.style.width = '100%';
            embed.style.height = '100vh';
            embed.style.border = 'none';
            container.appendChild(embed); // Append the embed element to the container
        } else if (applicationType !== "application/pdf") {
            // Trigger a download for unsupported file types
            const a = document.createElement("a");
            a.href = fileURL;
            a.download = fileName;
            a.click();
        } else {
            // if (openInSameWindow)
            //     window.location = fileURL;
            // else
            //     window.open(fileURL);
        }
    },

    delete: async function(type, fileName, auth0Client) {
        return await APICall.delete(`File/${type}/${fileName}`, auth0Client);
    },

    getReport: async function(reportViewName, auth0Client) {
        return await APICall.get(`File/Report/${reportViewName}`, auth0Client);
    },

    getReportViews: async function(auth0Client) {
        return await APICall.get(`File/ReportViews`, auth0Client);
    },
};

export default FileServiceClient;