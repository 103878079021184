import React, { useEffect, useRef } from 'react';
import { useAuth0 } from "../react-auth0-spa";
import { useParams } from 'react-router-dom';
import fileService from "../services/Common/FileServiceClient";
import { toast } from 'react-toastify';
import { CSpinner } from "@coreui/react";

const FileViewerPage = () => {
    const { isAuthenticated, loginWithRedirect, auth0Client } = useAuth0();
    const { type, fileName } = useParams();
    const containerRef = useRef(null);

    // Guard to ensure fetchFile runs only once
    const hasFetched = useRef(false);

    useEffect(() => {
        const fetchFile = async () => {
            if (hasFetched.current) return; // Prevent multiple executions
            hasFetched.current = true; // Mark as executed

            if (!isAuthenticated) {
                await loginWithRedirect({ appState: { targetUrl: window.location.pathname } });
            } else {
                const response = await fileService.get(type, fileName, auth0Client);
                if (response.succeeded) {
                    fileService.openFile(response.object, fileName, true, containerRef.current);
                } else {
                    toast.error('There was a problem downloading the file.');
                }
            }
        };

        fetchFile();
    }, [isAuthenticated, loginWithRedirect, auth0Client, type, fileName]);

    return (
        <div
            ref={containerRef}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh", 
            }}
        >
            <CSpinner color="info" className="card-spinner" />
        </div>
    );
};

export default FileViewerPage;
