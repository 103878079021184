import API from "./API.js";
import responseModel from "../models/Generic/responseModel";
//import config from "../auth_config.json";

const APICall = {
    post: async function(url, model, auth0Client) {
        // Get token
        const accessToken = await auth0Client.getTokenSilently({
            audience: process.env.REACT_APP_AUTH_AUDIENCE,
            scope: process.env.REACT_APP_AUTH_SCOPE,
        });

        API.defaults.headers.common = {'Authorization': `Bearer ${accessToken}`};
        let response = await API.post(url, model).catch(function (errorMessage) {
            let message = `${errorMessage }`;
            if (message !== null && message.includes("status code 403")) {
                message = "Your user doesn't have permissions to execute this action.";
            }
            return { 
                "data":  {
                    "object": null, 
                    "Errors": [ { "Description" : `${errorMessage }` }],
                    "succeeded": false
                }
            };
        });
        if (response !== null) {
            let result = response.data;
            if (result.succeeded === true) {
                return new responseModel(true, result.object,  null, result.additionalMessage,result.warnings);
            } else {
                return new responseModel(false, null, result.Errors.length > 0 ? result.Errors[0].Description.includes("status code 403") ? "Your user doesn't have permissions to execute this action." : result.Errors[0].Description : "There is a problem with the API service.");
            }               
        } else {
            return new responseModel(false, null, "There is a problem with the API service.", null);
        } 
    },

    get: async function(url, auth0Client) {
        // Get token
        const accessToken = await auth0Client.getTokenSilently({
            audience: process.env.REACT_APP_AUTH_AUDIENCE,
            scope: 'openid',
        });

        API.defaults.headers.common = {'Authorization': `Bearer ${accessToken}`};
        let response = await API.get(url).catch(function (errorMessage) {
            let message = `${errorMessage }`;
            if (message !== null && message.includes("status code 403")) {
                message = "Your user doesn't have permissions to execute this action.";
            }
            return { 
                "data":  {
                    "object": null, 
                    "Errors": [ { "Description" : `${errorMessage }` }],
                    "succeeded": false
                }
            };
        });
        if (response !== null) {
            let result = response.data;
            if (result.succeeded === true) {
                return new responseModel(true, result.object,  null, result.additionalMessage,result.warnings);
            } else {
                if (result.Errors !== undefined) {
                    return new responseModel(false, null, result.Errors.length > 0 ? result.Errors[0].Description : "There is a problem with the API service.");
                } else if (result.errors !== undefined) {
                    return new responseModel(false, null, result.errors.length > 0 ? result.errors[0].Description : "There is a problem with the API service.");
                }                
            }               
        } else {
            return new responseModel(false, null, "There is a problem with the API service.", null);
        } 
    },  

    put: async function(url, model, auth0Client) {
        // Get token
        const accessToken = await auth0Client.getTokenSilently({
            audience: process.env.REACT_APP_AUTH_AUDIENCE,
            scope: 'openid',
        });

        API.defaults.headers.common = {'Authorization': `Bearer ${accessToken}`};
        let response = await API.put(url, model).catch(function (errorMessage) {
            let message = `${errorMessage }`;
            if (message !== null && message.includes("status code 403")) {
                message = "Your user doesn't have permissions to execute this action.";
            }
            return { 
                "data":  {
                    "object": null, 
                    "Errors": [ { "Description" : `${errorMessage }` }],
                    "succeeded": false
                }
            };
        });
        if (response !== null) {
            let result = response.data;
            if (result.succeeded === true) {
                return new responseModel(true, result.object,  null, result.additionalMessage,result.warnings);
            } else {
                let errorMessage = result.Errors.length > 0 ? result.Errors[0].Description : "There is a problem with the API service.";
                if (errorMessage !== null && errorMessage.includes("status code 403")) {
                    errorMessage = "Your user doesn't have permissions to execute this action.";
                }
                return new responseModel(false, null, errorMessage);
            }               
        } else {
            return new responseModel(false, null, "There is a problem with the API service.", null);
        } 
    },

    delete: async function(url, auth0Client) {
        // Get token
        const accessToken = await auth0Client.getTokenSilently({
            audience: process.env.REACT_APP_AUTH_AUDIENCE,
            scope: 'openid',
        });

        API.defaults.headers.common = {'Authorization': `Bearer ${accessToken}`};
        let response = await API.delete(url).catch(function (errorMessage) {
            let message = `${errorMessage }`;
            if (message !== null && message.includes("status code 403")) {
                message = "Your user doesn't have permissions to execute this action.";
            }
            return { 
                "data":  {
                    "object": null, 
                    "Errors": [ { "Description" : `${message }` }],
                    "succeeded": false
                }
            };
        });
        if (response !== null) {
            let result = response.data;
            if (result.succeeded === true) {
                return new responseModel(true, result.object,  null, result.additionalMessage,result.warnings);
            } else {
                return new responseModel(false, null, result.Errors.length > 0 ? result.Errors[0].Description : "There is a problem with the API service.");
            }               
        } else {
            return new responseModel(false, null, "There is a problem with the API service.", null);
        } 
    }
};

export default APICall;